import request from '../utils/request'
import baseUrl from './baseUrl'

export const aboutus = (data) => request({
  url: baseUrl + '/aboutUs/selectForBack',
  method: 'POST',
  data
})
export const editaboutus = (data) => request({
  url: baseUrl + '/aboutUs/modify',
  method: 'POST',
  data
})

export const aboutusDetail = (data) => request({
  url: baseUrl + '/aboutUs/selectInfo',
  method: 'POST',
  data
})