<template>
  <div class="editAgree">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>标题</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="top">
      <span>协议名称</span>
      <el-input readonly v-model="name" placeholder="请输入协议名称"></el-input>
    </div>
    <div class="xiangqing">
      <span>详情</span>
      <!-- <quill-editor class="richEdit" v-model="EditText" :options="quillOption">
      </quill-editor> -->
      <VueUeditorWrap v-model="EditText" :config="myConfig"></VueUeditorWrap>
    </div>
    <div class="btn">
      <el-button type="primary" @click="postEdit">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
import { editaboutus, aboutusDetail } from "../../api/aboutus";
import VueUeditorWrap from "vue-ueditor-wrap";
import { Quill } from "vue-quill-editor";
import quillConfig from "../../../quill-config";
export default {
  name: "EditAgree",

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      myConfig: {
        toolbars: [
          [
            'simpleupload', //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度
      
          // serverUrl: "/api/config",
          // UEDITOR_HOME_URL: "/UEditor/",

        UEDITOR_HOME_URL: "/static/UEditor/",//打包
          serverUrl: "/config",//打包
        maximumWords: "100000000",
      },
      ueditor: {
        value: "编辑默认文字",
        config: {},
      },

      quillOption: quillConfig,
      EditText: "",
      name: "",
      aboutusDetails: {},
      editor: null,
    };
  },
   components: {    
    VueUeditorWrap,
  },
  mounted() {
    quillConfig.register(Quill);
    quillConfig.initButton();
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const { data } = await aboutusDetail({
        id: Number(this.id),
      });
      if (data.code == 0) {
        this.EditText = data.data.details;
        this.name = data.data.name;
      } else {
        this.$message.error(data.msg);
      }
    },
    async postEdit() {
      try {
        const { data } = await editaboutus({
          id: Number(this.id),
          detais: this.EditText,
        });
        if (data.code === 0) {
          this.$message({
            message: `编辑 --  ${this.name}  --成功`,
            type: "success",
          });
        }
        this.$router.back();
      } catch (error) {
        this.$message.error("编辑失败，请稍后重试");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.editAgree {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid #ccc;
  }
  .top {
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-left: 50px;
    span {
      // font-size: 18px;
    }
    div {
      display: inline-block;
      width: 200px;
      height: 40px;
      padding-left: 10px;
      line-height: 40px;
      margin-left: 50px;
    }
  }
  .xiangqing {
    display: flex;
    margin-top: 50px;
    span {
      margin-left: 50px;
    }
    /deep/ .richEdit {
      width: 1000px;
      height: 500px;
      margin-left: 80px;
      .ql-container {
        height: 400px;
      }
    }
  }
}
</style>